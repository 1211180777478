import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import './Gallery.css';

// import images

import gallery01 from '../../Assets/gallery/gallery01.webp';
import gallery02 from '../../Assets/gallery/gallery02.jpeg';
import gallery03 from '../../Assets/gallery/gallery03.webp';
import gallery04 from '../../Assets/gallery/gallery04.png';
import gallery05 from '../../Assets/gallery/gallery05.webp';

const images = [
    { src: gallery01, alt: "AIIMS" },
    { src: gallery02, alt: "AIIMS" },
    { src: gallery03, alt: "AIIMS" },
    { src: gallery04, alt: "AIIMS" },
    { src: gallery05, alt: "AIIMS" },
];

const Gallery = () => {
    return (
        <>
            <section className="">
                <div className="gallery-container">
                    <Swiper
                        modules={[Pagination, Navigation, Autoplay]}
                        spaceBetween={20}
                        slidesPerView={4} // Show 4 images by default
                        autoplay={{ delay: 1000, disableOnInteraction: false }}
                        pagination={{ clickable: true }}
                        loop={true}
                        className="mySwiper"
                        breakpoints={{
                            0: {
                                slidesPerView: 1, // Show 1 image on very small screens
                                spaceBetween: 10,
                            },
                            480: {
                                slidesPerView: 2, // Show 2 images on screens >= 480px
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 3, // Show 3 images on screens >= 768px
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3, // Show 4 images on screens >= 1024px
                                spaceBetween: 20,
                            },
                        }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={image.src} alt={image.alt} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Gallery;
