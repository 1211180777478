import React from 'react';
import { Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';

import './Home.css';

import Carasol from '../../Components/Carasol/Carasol';
import Gallery from '../../Components/Gallery/Gallery';
import ApplyJob from '../ApplyJob/ApplyJob';

const Home = () => {
  return (
    <>
     <Helmet>
        <title>All India Institute Of Medical Sciences</title>
        <meta name="description" content="Welcome to the All India Institute Of Medical Sciences, New Delhi. Learn about our history, explore job opportunities, and view our gallery." />
        <meta name="keywords" content="AIIMS, All India Institute Of Medical Sciences, Medical Sciences, Job Openings, Gallery" />
      </Helmet>
      <Carasol />

      <section className="home-content">
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 text-center my-2" style={{ color: "var(--color-began)" }}>
              <h4>अखिल भारतीय आयुर्विज्ञान संस्थान, नई दिल्ली</h4>
              <h4>All India Institute Of Medical Sciences, New Delhi</h4>
            </div>

            <div className="col-md-12">
              <p className='text-justify'>Creating a country imbued with a scientific culture was Jawaharlal Nehru's dream, and immediately after independence he prepared a grand design to achieve it. Among the temples of modern India which he designed, was a centre of excellence in the medical sciences. Nehru's dream was that such a centre would set the pace for medical education and research in Southeast Asia, and in this he had the wholehearted support of his Health Minister, Rajkumari Amrit Kaur.</p>
              <p className='text-justify'>The health survey and development committee, chaired by Sir Joseph Bhore, an Indian Civil Servant, had in 1946 already recommended the establishment of a national medical centre which would concentrate on meeting the need for highly qualified manpower to look after the nation's expanding health care activities. The dreams of Nehru and Amrit Kaur and the recommendations of the Bhore Committee converged to create a proposal which found favor with the government of New Zealand. A generous grant from New Zealand under the Colombo Plan made it possible to lay the foundation stone of All India Institute of Medical Sciences (AIIMS) in 1952. The AIIMS was finally created in 1956, as an autonomous institution through an Act of Parliament, to serve as a nucleus for nurturing excellence in all aspects of health care.</p>
              <p className='text-justify'>All-India Institute of Medical Sciences was established as an institution of national importance by an Act of Parliament with the objects to develop patterns of teaching in Undergraduate and Post-graduate Medical Education in all its branches so as to demonstrate a high standard of Medical Education in India; to bring together in one place educational facilities of the highest order for the training of personnel in all important branches of health activity; and to attain self-sufficiency in Post-graduate Medical Education.</p>
              <p className='text-justify'>The Institute has comprehensive facilities for teaching, research and patient care. As provided in the Act, AIIMS conducts teaching programs in medical and para-medical courses both at undergraduate and postgraduate levels and awards its own degrees. Teaching and research are conducted in 42 disciplines. In the field of medical research, AIIMS is the lead, having more than 600 research publications by its faculty and researchers in a year. AIIMS also runs a College of Nursing and trains students for B.Sc. (Hons.) Nursing post-certificate) degrees.</p>
              <p className='text-justify'>Twenty-five clinical departments including four super specialty centers manage practically all types of disease conditions with support from pre- and para-clinical departments. However, burn cases, dog-bite cases, and patients suffering from infectious diseases are not entertained in the AIIMS Hospital. AIIMS also manages a 60-bed hospital in the Comprehensive Rural Health Centre at Ballabgarh in Haryana and provides health cover to about 2.5 lakh population through the Centre for Community Medicine.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="job-opportunities">
        <div className="container my-5">
          <h2 className="section-title">Current Job Openings</h2>
          <p className='text-center'>We are excited to announce that several job openings are currently available at AIIMS. If you're passionate about contributing to the field of medical science and want to be part of a prestigious institution, this is your chance!</p>
          <p className='text-center'>Fill out the form below to apply for your dream job and take the next step in your career.</p>
          <p className='text-center'>Choose your desired position and submit your application. We look forward to reviewing your credentials and possibly welcoming you to our team.</p>
          <ApplyJob />
        </div>
      </section>

      <div className="container my-5 ">
        <h2 className="section-title">Our Gallery</h2>
        <Gallery />
      </div>
    </>
  );
};

export default Home;
