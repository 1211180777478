import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import organi1 from '../../Assets/organi1.jpg'

const Management = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>AIIMS New Delhi - Organization Structure</title>
                <meta name="description" content="Explore the organizational structure of the All India Institute of Medical Sciences (AIIMS), New Delhi, including key administrative roles and departments." />
            </Helmet>

            <section className="content">
                <div className="container my-5">
                    <h1>AIIMS New Delhi - Organization Structure</h1>
                    <p>
                        The All India Institute of Medical Sciences (AIIMS) in New Delhi is a premier medical institute renowned for its medical education, research, and patient care. The organization structure of AIIMS is designed to facilitate efficient management and operation of its various departments and functions.
                    </p>

                    <div className="row">
                        <div className="col-md-8 my-5 mx-auto">
                            <img src={organi1} alt="organi1" />
                        </div>
                    </div>

                    <section>
                        <h2>Administrative Structure</h2>
                        <p>
                            The administrative structure of AIIMS is led by a team of distinguished professionals, including the Director, Dean, and various Heads of Departments. This structure ensures that AIIMS maintains high standards in medical education, research, and clinical services.
                        </p>
                        <ul>
                            <li><strong>Director:</strong> The Director is the chief executive officer responsible for the overall management and functioning of the institute.</li>
                            <li><strong>Dean:</strong> The Dean oversees academic and research activities and ensures the integration of teaching and research.</li>
                            <li><strong>Medical Superintendent:</strong> The Medical Superintendent manages the hospital operations and clinical services.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Departments and Divisions</h2>
                        <p>
                            AIIMS New Delhi comprises various departments and divisions that contribute to its comprehensive medical education and healthcare delivery. Each department is led by a Head of Department who is responsible for the academic and clinical activities within their domain.
                        </p>
                        <ul>
                            <li><strong>Department of Medicine:</strong> Focuses on internal medicine, clinical care, and research.</li>
                            <li><strong>Department of Surgery:</strong> Specializes in surgical procedures, training, and research.</li>
                            <li><strong>Department of Pediatrics:</strong> Provides healthcare for children and conducts pediatric research.</li>
                            <li><strong>Department of Obstetrics and Gynecology:</strong> Offers care in women's health, including maternity services.</li>
                            <li><strong>Department of Radiology:</strong> Manages diagnostic imaging and radiological services.</li>
                            <li><strong>Department of Pathology:</strong> Handles diagnostic laboratory services and research in pathology.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Committees and Boards</h2>
                        <p>
                            Various committees and boards are established to oversee different aspects of the institute's operations, including academic programs, research activities, and hospital management.
                        </p>
                        <ul>
                            <li><strong>Academic Council:</strong> Responsible for curriculum development and academic standards.</li>
                            <li><strong>Research Committee:</strong> Oversees research projects and ethical standards.</li>
                            <li><strong>Hospital Advisory Committee:</strong> Provides guidance on hospital management and patient care.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>Contact Information</h2>
                        <p>
                            For more information about AIIMS New Delhi's organizational structure or specific departments, please contact:
                        </p>
                        <address>
                            <p><strong>All India Institute of Medical Sciences</strong></p>
                            <p>Ansari Nagar, New Delhi - 110029</p>
                            <p>Phone: +91-11-26588500</p>
                            <p>Email: info@aiims.edu.in</p>
                        </address>
                    </section>
                </div>
            </section>
        </>
    );
}

export default Management;
