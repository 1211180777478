import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Mom = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>AIIMS New Delhi - Minutes of Meetings (Governing Body)</title>
                <meta name="description" content="View the minutes of the Governing Body meetings of AIIMS New Delhi, including dates, locations, and key decisions." />
            </Helmet>

            <section className="content">
                <div className="container my-5">
                    <h1 className="mb-4">Minutes of Meetings (Governing Body)</h1>
                    <p className="lead mb-4">
                        Welcome to the archive of the Governing Body meetings of the All India Institute of Medical Sciences (AIIMS) New Delhi. These meetings are held to discuss critical decisions, policies, and strategic plans for the institution. Below is a summary of the minutes from various meetings, highlighting the dates and key details.
                    </p>

                    <section className="mb-5">
                        <h2 className="mb-3">Meeting Overview</h2>
                        <p>
                            The Governing Body meetings are essential for the governance and strategic direction of AIIMS. They involve discussions on administrative matters, academic policies, and other significant issues affecting the institute. Here are the details of the meetings held over the years:
                        </p>
                    </section>

                    <section className="mb-5">
                        <h2 className="mb-3">Minutes of Meetings</h2>
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <strong>151st Meeting:</strong> Held on <strong>12 May 2014</strong> in Committee Room, M/o Health & Family Welfare, Nirman Bhawan, New Delhi.
                                <p className="mt-2">
                                    This meeting covered key administrative changes and updates on ongoing projects.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>152nd Meeting:</strong> Held on <strong>13 April 2015</strong> in Conference Room, M/o Health & Family Welfare, Nirman Bhawan, New Delhi.
                                <p className="mt-2">
                                    Discussions focused on academic programs and new initiatives for research collaboration.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>153rd Meeting:</strong> Held on <strong>22 June 2016</strong> in Conference Room, M/o Health & Family Welfare, Nirman Bhawan, New Delhi.
                                <p className="mt-2">
                                    This session included reviews of institutional performance and strategic planning.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>154th Meeting:</strong> Held on <strong>21 October 2016</strong> in Conference Room, Hon'ble HFM's, Nirman Bhawan, New Delhi.
                                <p className="mt-2">
                                    Key decisions on funding and infrastructure development were discussed.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>155th Meeting:</strong> Held on <strong>10 October 2017</strong> in Conference Room, Hon'ble HFM's, Nirman Bhawan, New Delhi.
                                <p className="mt-2">
                                    Focus was on enhancing academic and clinical research programs.
                                </p>
                            </li>
                            <li className="mb-2">
                                <strong>157th Meeting:</strong> Held on <strong>24 January 2019</strong> in Dr. Ramalingaswami Board Room, New Delhi.
                                <p className="mt-2">
                                    Discussions included policy changes and future plans for expansion.
                                </p>
                            </li>
                        </ul>
                    </section>

                    <section className="mb-5">
                        <h2 className="mb-3">Additional Information</h2>
                        <p>
                            For more detailed information or to access full minutes from these meetings, please contact the administrative office or visit the official AIIMS website.
                        </p>
                    </section>

                    <section className="mb-5">
                        <h2 className="mb-3">Contact Information</h2>
                        <p>
                            For further inquiries or specific information related to the Governing Body meetings, you can reach out to:
                        </p>
                        <address>
                            <p><strong>All India Institute of Medical Sciences</strong></p>
                            <p>Ansari Nagar, New Delhi - 110029</p>
                            <p>Phone: +91-11-26588500</p>
                            <p>Email: info@aiims.edu.in</p>
                        </address>
                    </section>
                </div>
            </section>
        </>
    );
}

export default Mom;
