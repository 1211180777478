import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const DepartmentCenters = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Department Centers - Overview and Details</title>
                <meta name="keywords" content="Departments, Centers, Overview, Details, Facilities" />
                <meta name="description" content="Learn more about our departments and the centers associated with each, providing specialized services and facilities." />
            </Helmet>
            <section className='content'>
                <div className="container my-5">
                    <h1 className="mb-4">Our Department Centers</h1>
                    <p>Our institution is organized into various departments, each focusing on different aspects of medical science and healthcare. Each department operates specialized centers that offer comprehensive services and advanced facilities. Below, you'll find an overview of our main departments and the centers they manage.</p>
                    
                    <h2 className="mt-5 mb-2">Department of Cardiology</h2>
                    <p>The Department of Cardiology is dedicated to the diagnosis and treatment of heart-related conditions. Our center includes state-of-the-art facilities for cardiac imaging, electrophysiology, and interventional cardiology.</p>
                    
                    <h2 className="mt-5 mb-2">Department of Neurology</h2>
                    <p>The Neurology Department focuses on diseases and disorders of the nervous system. Our center is equipped with advanced diagnostic tools for neuroimaging, neurophysiology, and comprehensive neuro-rehabilitation services.</p>
                    
                    <h2 className="mt-5 mb-2">Department of Oncology</h2>
                    <p>Our Oncology Department provides cutting-edge treatments for various types of cancer. The center offers facilities for chemotherapy, radiotherapy, and personalized cancer care, alongside support services for patients and families.</p>
                    
                    <h2 className="mt-5 mb-2">Department of Pediatrics</h2>
                    <p>The Pediatrics Department specializes in the healthcare of infants, children, and adolescents. The center includes pediatric emergency care, developmental assessments, and specialized treatments for a range of childhood diseases.</p>

                    <h2 className="mt-5 mb-2">Department of Orthopedics</h2>
                    <p>The Orthopedics Department is dedicated to the treatment of musculoskeletal disorders. Our center features advanced surgical facilities, orthopedic rehabilitation, and sports medicine services.</p>
                    
                    <h2 className="mt-5 mb-2">Department of Gynecology</h2>
                    <p>The Gynecology Department focuses on women's health, including reproductive health and maternity care. The center offers services for obstetric care, gynecological surgeries, and women's wellness programs.</p>

                    <h2 className="mt-5 mb-2">Additional Services</h2>
                    <p>In addition to the specialized departments, our institution offers a range of support services including diagnostic labs, physiotherapy centers, and wellness programs designed to provide comprehensive care to our patients.</p>
                </div>
            </section>
        </>
    );
};

export default DepartmentCenters;
