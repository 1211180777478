import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const JobInvitation = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>AIIMS New Delhi - Job Opportunities</title>
                <meta name="description" content="Explore exciting career opportunities at AIIMS New Delhi. Apply now to join our esteemed team and make a difference in healthcare." />
            </Helmet>

            <section className="content">
                <div className="container my-5">
                    <div className="text-center mb-5">
                        <h1 className="mb-4">Join Our Team at AIIMS New Delhi!</h1>
                        
                        <p className="lead">
                            At AIIMS New Delhi, we are dedicated to excellence in medical education, research, and patient care. We are seeking talented and passionate individuals to join our dynamic team and contribute to our mission of advancing healthcare.
                        </p>
                    </div>

                    <section className="text-center mb-5">
                        <h2 className="mb-3">Ready to Apply?</h2>
                        <p>
                            If you are enthusiastic about joining our team and contributing to our mission, apply now by clicking the button below.
                        </p>
                        <Link to="/apply-job" className="btn btn-primary btn-lg">
                            Apply for Job Now
                        </Link>
                    </section>

                    <section className="mb-5">
                        <h2 className="mb-3">Current Job Openings</h2>
                        <p>
                            We are excited to announce the following job openings. If you are looking for a challenging and rewarding career in healthcare, we would love to hear from you!
                        </p>
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <h3>Medical Research Scientist</h3>
                                <p>
                                    Contribute to groundbreaking research in medical science and help drive innovations in patient care.
                                </p>
                            </li>
                            <li className="mb-3">
                                <h3>Clinical Nurse Specialist</h3>
                                <p>
                                    Provide expert care and support to patients in a fast-paced hospital environment.
                                </p>
                            </li>
                            <li className="mb-3">
                                <h3>Administrative Coordinator</h3>
                                <p>
                                    Manage and coordinate administrative functions to ensure smooth operations within the institute.
                                </p>
                            </li>
                        </ul>
                    </section>

                    <section className="mb-5">
                        <h2 className="mb-3">Why Work with Us?</h2>
                        <p>
                            AIIMS New Delhi offers a vibrant and collaborative work environment where you can grow professionally and make a meaningful impact. We provide competitive salaries, comprehensive benefits, and opportunities for career advancement.
                        </p>
                        <ul>
                            <li>Innovative and supportive work culture</li>
                            <li>Opportunities for professional development</li>
                            <li>Attractive compensation packages</li>
                            <li>State-of-the-art facilities and resources</li>
                        </ul>
                    </section>

                    
                </div>
            </section>
        </>
    );
}

export default JobInvitation;
