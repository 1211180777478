import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Updates = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Updates - Latest News and Announcements</title>
                <meta name="keywords" content="Updates, News, Announcements, Latest News" />
                <meta name="description" content="Stay updated with the latest news, announcements, and updates from our organization. Find out about recent changes and upcoming events." />
            </Helmet>
            <section className='content'>
                <div className="container my-5">
                    <h1 className="mb-4">Latest Updates</h1>
                    <p>Welcome to our updates page. Here you can find the latest news, announcements, and important updates about our organization. We strive to keep you informed about recent changes, upcoming events, and significant developments.</p>


                    <h2 className="mt-5 mb-2">Job Opportunities</h2>
                    <p>We are currently looking for talented individuals to join our team. If you are interested in applying, please visit our job application page.</p>
                    <a href="/apply-job" className="btn btn-primary">Apply for a Job</a>
                </div>
            </section>
        </>
    );
};

export default Updates;
