import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Director = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Professor Bhalachandra Babaji Dikshit - Legacy and Contributions</title>
                <meta name="description" content="Explore the life, career, and achievements of Professor Bhalachandra Babaji Dikshit, a distinguished physiologist, pharmacologist, and administrator." />
            </Helmet>

            <section className="content">
                <div className="container my-5">
                    <h1>Professor Bhalachandra Babaji Dikshit (1902-1977)</h1>
                    <h2>Physiologist and Pharmacologist, Teacher and Administrator</h2>

                    <section>
                        <h3>Early Life and Career</h3>
                        <p>
                            Born on September 7, 1902, in Amravati to a family of esteemed lawyers, Bhalachandra Babaji Dikshit chose a different path, opting for a career in medicine. He graduated from Grant Medical College, Bombay, in 1925, marking the beginning of a distinguished career in the medical field.
                        </p>
                        <p>
                            His career was divided into two significant phases: the first twenty years were marked by his contributions as a scientist, while the latter twenty years highlighted his excellence as a teacher and administrator.
                        </p>
                    </section>

                    <section>
                        <h3>B.B. Dikshit, the Scientist (1926-1946)</h3>
                        <p>
                            Dr. Dikshit's research career commenced in 1926 at the School of Tropical Medicine and Hygiene in Calcutta. Under the guidance of Prof. R.N. Chopra, he conducted pioneering work on anti-malarial alkaloids, pseudoephedrine, and narcotine. His studies laid the groundwork for future research in pharmacology.
                        </p>
                        <p>
                            By 1930, at the age of 28, he became the Professor of Pharmacology at Medical College, Vishakhapatnam. His work on chaulmoogra oil for leprosy and pertain as a spinal anesthetic was notable. Despite facing challenges, including an untimely dismissal in 1931, his reputation continued to grow.
                        </p>
                        <p>
                            In 1931, Dr. Dikshit moved to Edinburgh to pursue further studies, where he earned his M.R.C.P. and Ph.D. His research on acetylcholine as a central neurotransmitter and its effects on cardiac irregularities contributed significantly to the field. His work in Edinburgh remains influential, particularly his studies on the effects of caffeine and sodium barbitone on cardiac irregularities.
                        </p>
                        <p>
                            Returning to India in 1934, he joined the Haffkine Institute, where he expanded his research to include immunology of malaria, drug studies, and established a snake farm for venom studies. His wartime research demonstrated his ability to handle urgent and unconventional problems with ingenuity.
                        </p>
                    </section>

                    <section>
                        <h3>B.B. Dikshit, the Teacher and Administrator (1946-1964)</h3>
                        <p>
                            In 1946, Dr. Dikshit was appointed Principal and Professor of Physiology at B.J Medical College, Poona. His role in upgrading the college for medical graduate training showcased his administrative and teaching skills. His appointment as Surgeon-General to the Government of Bombay in 1951 expanded his influence to health care and medical education throughout the Bombay Presidency.
                        </p>
                        <p>
                            The establishment of the All India Institute of Medical Sciences (AIIMS) in 1956 marked a pinnacle in his career. As its first Director, Dr. Dikshit played a crucial role in shaping the institution into a leading center of medical excellence. His leadership attracted top Indian medical scientists and set a high standard for medical education and research.
                        </p>
                    </section>

                    <section>
                        <h3>B.B. Dikshit, the Person</h3>
                        <p>
                            Dr. Dikshit was known for his clear-headed approach to administration, characterized by fairness and integrity. His comments on administrative matters were succinct and to the point, often remembered fondly as ‘Yes, BBD’ or ‘No, BBD’.
                        </p>
                        <p>
                            His genuine, selfless love for his students was remarkable. He was a university-level hockey player and later enjoyed tennis and badminton, sharing his love for sports with the youth. His popularity among students stemmed from his paternal attitude, which fostered unconditional love and respect.
                        </p>
                        <p>
                            Prof. N.K. Bhide, his student and colleague, noted Dr. Dikshit’s deeply spiritual qualities, which contributed to his universal and selfless love. His respect for authority, contentment, and refusal to seek personal gains were reflections of his spiritual values.
                        </p>
                        <p>
                            Dr. Dikshit’s final days were marked by clarity and peace. He encouraged his wife to visit her parental home even when his health was deteriorating. He passed away the day after her return, embodying the simplicity and integrity of his life.
                        </p>
                    </section>

                    <section>
                        <h3>Acknowledgment</h3>
                        <p>
                            I am grateful to Prof. Mohan B. Dikshit for providing invaluable material and to Prof. N.K. Bhide for offering insights into the remarkable life of Prof. B.B. Dikshit.
                        </p>
                        <p>
                            — R.L. Bijlani, Department of Physiology, All India Institute of Medical Sciences, New Delhi
                        </p>
                    </section>
                </div>
            </section>
        </>
    );
}

export default Director;
