import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';


const About = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    })
    return (
        <>
            <Helmet>
                <title>About AIIMS - Overview and Mission</title>
                <meta name="keywords" content="AIIMS, Overview, Mission, Medical Institution, Healthcare" />
                <meta name="description" content="Learn more about AIIMS, its mission, and its role in advancing medical education and healthcare in India." />
            </Helmet>
            <section className='content'>
                <div className="container my-5">
                    <h1 className="mb-4">Introduction</h1>
                    <p>Creating a country imbued with a scientific culture was Jawaharlal Nehru's dream, and immediately after independence he prepared a grand design to achieve it. Among the temples of modern India which he designed, was a centre of excellence in the medical sciences. Nehru's dream was that such a centre would set the pace for medical education and research in Southeast Asia, and in this he had the wholehearted support of his Health Minister, Rajkumari Amrit Kaur.</p>
                    <p>The health survey and development committee, chaired by Sir Joseph Bhore, an Indian Civil Servant, had in 1946 already recommended the establishment of a national medical centre which would concentrate on meeting the need for highly qualified manpower to look after the nation's expanding health care activities. The dreams of Nehru and Amrit Kaur and the recommendations of the Bhore Committee converged to create a proposal which found favor with the government of New Zealand. A generous grant from New Zealand under the Colombo Plan made it possible to lay the foundation stone of All India Institute of Medical Sciences (AIIMS) in 1952. The AIIMS was finally created in 1956, as an autonomous institution through an Act of Parliament, to serve as a nucleus for nurturing excellence in all aspects of health care.</p>
                    <p>All-India Institute of Medical Sciences was established as an institution of national importance by an Act of Parliament with the objects to develop patterns of teaching in Undergraduate and Post-graduate Medical Education in all its branches so as to demonstrate a high standard of Medical Education in India; to bring together in one place educational facilities of the highest order for the training of personnel in all important branches of health activity; and to attain self-sufficiency in Post-graduate Medical Education.</p>
                    <p>The Institute has comprehensive facilities for teaching, research and patient care. As provided in the Act, AIIMS conducts teaching programs in medical and para-medical courses both at undergraduate and postgraduate levels and awards its own degrees. Teaching and research are conducted in 42 disciplines. In the field of medical research, AIIMS is the lead, having more than 600 research publications by its faculty and researchers in a year. AIIMS also runs a College of Nursing and trains students for B.Sc. (Hons.) Nursing post-certificate) degrees.</p>
                    <p>Twenty-five clinical departments including four super specialty centers manage practically all types of disease conditions with support from pre- and para-clinical departments. However, burn cases, dog-bite cases, and patients suffering from infectious diseases are not entertained in the AIIMS Hospital. AIIMS also manages a 60-bed hospital in the Comprehensive Rural Health Centre at Ballabgarh in Haryana and provides health cover to about 2.5 lakh population through the Centre for Community Medicine.</p>

                    <h2 className="mt-5 mb-2">Objectives of AIIMS</h2>
                    <ul className="list-styled">
                        <li className="mb-2">To develop a pattern of teaching in undergraduate and postgraduate medical education in all its branches so as to demonstrate a high standard of medical education to all medical colleges and other allied institutions in India.</li>
                        <li className="mb-2">To bring together in one place educational facilities of the highest order for the training of personnel in all important branches of health activity.</li>
                        <li className="mb-2">To attain self-sufficiency in postgraduate medical education.</li>
                    </ul>

                    <h2 className="mt-5 mb-2">Functions of AIIMS</h2>
                    <ul className="list-styled">
                        <li className="mb-2">Undergraduate and postgraduate teaching in medical and related physical biological sciences.</li>
                        <li className="mb-2">Nursing and dental education.</li>
                        <li className="mb-2">Innovations in education.</li>
                        <li className="mb-2">Producing medical teachers for the country.</li>
                        <li className="mb-2">Research in medical and related sciences.</li>
                        <li className="mb-2">Health care: preventive, promotive and curative; primary, secondary & tertiary.</li>
                        <li className="mb-2">Community-based teaching and research.</li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default About;
