import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Library = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Library - Explore Our Collection and Resources</title>
                <meta name="keywords" content="Library, Books, Resources, Catalog, Study Materials" />
                <meta name="description" content="Explore our library's collection, including books, journals, and digital resources. Learn about our services and how to access our catalog." />
            </Helmet>
            <section className='content'>
                <div className="container my-5">
                    <h1 className="mb-4">Library</h1>
                    <p>Welcome to our library! We offer a diverse range of resources including books, journals, and digital materials to support your educational and research needs. Explore our collection and make use of the various services we provide.</p>
                    
                    <h2 className="mt-4 mb-2">Our Collection</h2>
                    <p>Our library houses a comprehensive collection of:</p>
                    <ul className="list-styled">
                        <li className="mb-2">Books across various genres and disciplines</li>
                        <li className="mb-2">Academic journals and research papers</li>
                        <li className="mb-2">Digital resources and e-books</li>
                        <li className="mb-2">Historical archives and special collections</li>
                    </ul>

                    <h2 className="mt-5 mb-2">Library Services</h2>
                    <p>We provide a range of services to assist with your research and learning needs:</p>
                    <ul className="list-styled">
                        <li className="mb-2">Access to digital databases and online journals</li>
                        <li className="mb-2">Research assistance and reference services</li>
                        <li className="mb-2">Interlibrary loan services</li>
                        <li className="mb-2">Quiet study areas and group study rooms</li>
                    </ul>

                    <h2 className="mt-5 mb-2">How to Access Our Catalog</h2>
                    <p>You can browse our full catalog online. Visit our library catalog page to search for books, journals, and other resources.</p>

                    <h2 className="mt-5 mb-2">Contact Us</h2>
                    <p>If you have any questions or need assistance, please contact our library staff:</p>
                    <ul>
                        <li>Email: <a href="mailto:support@aiims-jobs.com" className="link-primary">support@aiims-jobs.com</a></li>
                        <li>Phone: <a href="tel:+918420580757" className="link-primary">+91-8420580757</a></li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default Library;
