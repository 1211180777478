import React, { useEffect } from 'react';
import './Contact.css';

const Contact = () => {
    useEffect(()=>{
      window.scrollTo({
        top:0,
        behavior:'smooth'
      })
    },[])
  return (
    <div className="contact-page">
      <section className="contact-header text-center">
        <h1>Contact Us</h1>
        <p>We're here to help! Reach out to us for any inquiries or support.</p>
      </section>

      <section className="contact-info">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Get in Touch</h2>
              <p>If you have any questions or need assistance, feel free to contact us using the form below, or reach out to us via phone or email.</p>
              <ul className="contact-details">
                <li><strong>Phone:</strong> +91-8420580757</li>
                <li><strong>Email:</strong> <a href="mailto:support@aiims-jobs.com">support@aiims-jobs.com</a></li>
                <li><strong>Address:</strong> All India Institute of Medical Sciences Ansari Nagar, New Delhi - 110029</li>
              </ul>
            </div>
            <div className="col-md-6">
              <h2>Contact Form</h2>
              <form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" className="form-control" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" className="form-control" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea id="message" className="form-control" rows="4" placeholder="Your Message" required></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
