import React, { useEffect, useState } from 'react';
import './CenterLogin.css'; // Custom CSS for styling

const CenterLogin = () => {

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        rememberMe: false,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoginFailed, setIsLoginFailed] = useState(false);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.username) tempErrors.username = 'Username or Email is required';
        if (!formData.password) tempErrors.password = 'Password is required';
        if (formData.password && formData.password.length < 6)
            tempErrors.password = 'Password must be at least 6 characters long';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // If no errors, return true
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setIsLoginFailed(true); // Always show "Wrong username or password" if validation passes
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    return (
        <div className="container my-5">
            <div className="center-login-page">
                <div className="login-container">
                    <h2 className="login-head">Staff Login</h2>
                    <p className="login-para">Please log in to access the client portal.</p>
                    <form onSubmit={handleSubmit} className="login-form">

                        <div className="form-group">
                            <label htmlFor="username">Username or Email</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                                value={formData.username}
                                onChange={handleChange}
                                placeholder="Enter your username or email"
                                required
                            />
                            {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                        </div>
                        <div className="form-group position-relative">
                            <label htmlFor="password">Password</label>
                            <div className="input-group">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    className={`form-control password-input ${errors.password ? 'is-invalid' : ''}`}
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Enter your password"
                                    required
                                />
                                <i
                                    className={`password-toggle-icon ${showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}
                                    onClick={togglePasswordVisibility}
                                    role="button"
                                    aria-label="Toggle Password Visibility"
                                ></i>
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            </div>
                        </div>
                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                id="rememberMe"
                                name="rememberMe"
                                className="form-check-input"
                                checked={formData.rememberMe}
                                onChange={handleChange}
                            />
                            <label htmlFor="rememberMe" className="form-check-label">
                                Remember Me
                            </label>
                        </div>
                        {isLoginFailed && (
                            <div className="alert alert-danger mt-3">
                                Wrong username or password
                            </div>
                        )}
                        <button type="submit" className="btn btn-primary btn-block">
                            Log In
                        </button>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default CenterLogin;
