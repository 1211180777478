import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom'
import logo from '../../Assets/logo.png'

const Header = () => {
  const menuData = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      link: "#",
      subMenu: [
        { name: "Introduction", link: "/about-us/introduction" },
        { name: "About AIIMS", link: "/about-us/about-aiims" },
        { name: "Teaching", link: "/about-us/teaching" },
        { name: "Research", link: "/about-us/research" },
        { name: "Patient Care", link: "/about-us/patient-care" },
        { name: "Director", link: "/about-us/director" },
        { name: "Management", link: "/about-us/management" },
        { name: "Minutes of Meeting", link: "/about-us/minutes-of-meeting" },
      ],
    },
    {
      name: "Staff Services",
      link: "#",
      subMenu: [
        { name: "Staff Login", link: "/staff-services/staff-login" },
        { name: "Apply For Operational Trial", link: "/staff-services/job-invitation" },
      ],
    },
    { name: "Departments & Centers", link: "/departments-centers" },
    { name: "Library", link: "/library" },
    { name: "Events", link: "/events" },
    { name: "Contact Us", link: "/contact" },
    { name: "Updates", link: "/updates" },
    { name: "Apply Now", link: "/apply-job" },
  ];
  
  const [menu, setmenu] = useState(false);
  const handleMenuToggle = () => {
    setmenu(!menu)
  }
  return (
    <>

      <header>

        <div className="above-line container-fluid">

          <div className="contact-info">
            <div className="single-info">
              <i class="fa-solid fa-phone-volume"></i>
              <a href="tel:+918420580757">+91-8420580757</a>
            </div>
            <div className="single-info">
              <i class="fa-solid fa-envelope-open-text"></i>
              <a href="mailto:support@aiims-jobs.com">support@aiims-jobs.com</a>
            </div>
          </div>

          <div className="login-links">
            <Link to={`/staff-services/staff-login`}>Staff Login</Link>
            <Link to={`/staff-services/job-invitation`}>Apply For Operational Trial</Link>
          </div>
        </div>

        <div className="">

          {/* -- Above Head --  */}
          <div className='head'>

            <div className="logo">
              <Link to={`/`}>
                <img src={logo} alt="Manovaidya logo" />
              </Link>
            </div>


            <div className="side-icons">
              <div className="headline">
                <h4>अखिल भारतीय आयुर्विज्ञान संस्थान, नई दिल्ली</h4>
                <h4>All India Institute Of Medical Sciences, New Delhi</h4>
              </div>

              <div className="icon hamburger" onClick={handleMenuToggle}>
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>

          </div>

          {/* Nav Links */}
          <div className={`nav-links ${menu ? "active" : ""}`}>
            <ul>
              {menuData.map((item, index) => (
                <li
                  key={index}
                  className={item.subMenu ? "dropdown" : ""}
                  onClick={!item.subMenu ? handleMenuToggle : undefined}
                >
                  <Link to={item.link}>{item.name}</Link>
                  {item.subMenu && (
                    <ul className="dropdown-menu">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to={subItem.link} onClick={handleMenuToggle}>{subItem.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>



        </div>

      </header>

      {/* <!-- ----------Whatsapp---------- --> */}
      <a href="https://api.whatsapp.com/send?phone=918420580757" target="_blank" class="whatsapp_float"><i class="fa-brands fa-whatsapp whatsapp-icon"></i></a>

      <Link to="/apply-job" className="job-apply_float" aria-label="Apply for Job">
        <div className="job-apply-content">
          <i className="fa-solid fa-briefcase job-apply-icon"></i>
          <span className="job-apply-text">Apply Now</span>
        </div>
      </Link>

    </>
  )
}

export default Header