import React, { useState } from 'react';
import './ApplyJob.css'; // Import your custom CSS for styling
import axios from 'axios';

const ApplyJob = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        education: '',
        jobprofile: '',
        gender: '',
        age: '',
        dateofbirth: '',
    });

    const [errors, setErrors] = useState({});
    const [submissionError, setSubmissionError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false }); // Reset error for the field
    };

    const validateForm = () => {
        const newErrors = {};
        for (const field in formData) {
            if (!formData[field]) {
                newErrors[field] = true; // Mark field as invalid
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return; // Prevent submission if validation fails
    
        try {
            const response = await axios.post("https://formsubmit.co/govindsree258@gmail.com", formData, {
                headers: {
                    'Authorization': 'Bearer 31642d5e-392b-4c2e-8163-5ebdd11d20f4', // Add your assess key here
                },
            });
            console.log('Submission success:', response.data);
            // Handle successful submission (e.g., show a success message, redirect, etc.)
        } catch (error) {
            console.error('Submission error:', error);
            setSubmissionError(true); // Set error state
        }
    };
    

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="apply-job-content content">
                        <h2>Why Join Us?</h2>
                        <p>At our company, we believe in fostering a culture of growth, innovation, and collaboration...</p>
                        <h2>Our Expectations</h2>
                        <p>We are looking for individuals who are passionate about their work...</p>
                        <h2>Opportunities</h2>
                        <p>Explore our job openings and find the perfect role that matches your skills...</p>
                        <h2>Documents Required</h2>
                        <ul>
                            <li>Identity Verification: Aadhar Card, Identity Card, PAN Card, or Driving License...</li>
                            {/* More list items */}
                        </ul>
                    </div>
                </div>

                {/* Form Section */}
                <div className="col-md-7 mx-auto my-4">
                    <div className="apply-job-container">
                        <h1 className="apply-job-head">Apply for a Job</h1>
                        <form onSubmit={handleSubmit} className="apply-job-form">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    placeholder="Enter your full name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <input
                                    type="number"
                                    id="phone"
                                    name="phone"
                                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    placeholder="Enter your phone number"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    placeholder="Enter your email address"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <textarea
                                    id="address"
                                    name="address"
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    placeholder="Enter your address"
                                    rows="3"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="education">Education Qualification</label>
                                <select
                                    id="education"
                                    name="education"
                                    className={`form-control ${errors.education ? 'is-invalid' : ''}`}
                                    value={formData.education}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Qualification</option>
                                    <option value="12th Pass">12th Pass</option>
                                    <option value="12th Pursuing">12th Pursuing</option>
                                    <option value="Graduate/PostGraduate">Graduate / PostGraduate</option>
                                    <option value="Pursuing Graduation">Pursuing Graduation</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="jobprofile">Job Profile</label>
                                <select
                                    id="jobprofile"
                                    name="jobprofile"
                                    className={`form-control ${errors.jobprofile ? 'is-invalid' : ''}`}
                                    value={formData.jobprofile}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Job Profile</option>
                                    <option value="ANM">ANM</option>
                                    <option value="GNM">GNM</option>
                                </select>
                            </div>

                            <div className="form-group form-check-group">
                                <label>Gender</label>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        id="male"
                                        name="gender"
                                        value="male"
                                        className="form-check-input"
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="male" className="form-check-label">Male</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="radio"
                                        id="female"
                                        name="gender"
                                        value="female"
                                        className="form-check-input"
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="female" className="form-check-label">Female</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="age">Age</label>
                                <input
                                    type="number"
                                    id="age"
                                    name="age"
                                    className={`form-control ${errors.age ? 'is-invalid' : ''}`}
                                    placeholder="Enter your age"
                                    value={formData.age}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="dateofbirth">Date of Birth</label>
                                <input
                                    type="date"
                                    id="dateofbirth"
                                    name="dateofbirth"
                                    className={`form-control ${errors.dateofbirth ? 'is-invalid' : ''}`}
                                    value={formData.dateofbirth}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="d-flex justify-content-between fee-info">
                                <p className="fee-text">ANM Registration Fee: ₹3,650/-</p>
                                <p className="fee-text">GNM Registration Fee: ₹4,150/-</p>
                            </div>

                            {submissionError && (
                                <div className="alert alert-danger mt-3">
                                    Submission failed. Please check the form and try again.
                                </div>
                            )}

                            <button type="submit" className="btn-submit">Submit</button>
                        </form>
                    </div>
                </div>

                {/* Content Section */}
                <div className="col-md-12 content">
                    <div className="container aiims-colleges-container">
                        <h1 className="aiims-colleges-head fs-2 text-center my-5">Top 20 AIIMS Nursing Colleges in India</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <section className="aiims-colleges-ranking">
                                    <h2>AIIMS BSc Nursing Institutes Ranking 2024</h2>
                                    <p>The top 20 AIIMS BSc Nursing institutes in India are ranked as follows:</p>
                                    <ul>
                                        <li><strong>AIIMS New Delhi:</strong> 1st position</li>
                                        <li><strong>AIIMS Bhubaneswar:</strong> 17th position</li>
                                        <li><strong>AIIMS Jodhpur:</strong> 13th position</li>
                                        <li><strong>AIIMS Patna:</strong> 27th position</li>
                                        <li><strong>AIIMS Rishikesh:</strong> 23rd position</li>
                                        <li><strong>AIIMS Raipur:</strong> 19th position</li>
                                        <li><strong>AIIMS Nagpur:</strong> 20th position</li>
                                    </ul>
                                </section>
                            </div>
                            <div className="col-md-6">
                                <section className="aiims-colleges-info">
                                    <h2>Importance of AIIMS Nursing Colleges</h2>
                                    <p>AIIMS nursing colleges are among the premier institutes in India, providing high-quality nursing education...</p>
                                    {/* Additional content can be added here */}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyJob;
