import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const PatientCare = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Patient Care at AIIMS - Excellence in Medical and Surgical Services</title>
                <meta name="description" content="Explore how AIIMS delivers world-class medical and surgical care, its advanced technologies, and community outreach programs." />
                <meta name="keywords" content="AIIMS patient care, medical services, surgical care, healthcare technologies, community health" />
            </Helmet>
            <section className="content">
                <div className="container my-5">
                    <h1>Excellence in Medical and Surgical Care</h1>
                    <p>
                        Our results in sophisticated medical and surgical care are among the best in the world. The most satisfying aspect of this endeavor is that these cutting-edge modalities are being actually used for the poor.
                    </p>

                    <h2>Commitment to the Underprivileged</h2>
                    <p>
                        AIIMS strives hard to make world-class clinical care available to millions of underprivileged citizens of India and neighboring countries. Despite its magnificent buildings and hi-tech facilities, AIIMS dispels any illusions of elitism. Its corridors are thronged by patients, many of whom are too poor to afford quality treatment elsewhere. They come with high hopes due to AIIMS's established reputation.
                    </p>

                    <h2>AIIMS Facilities</h2>
                    <p>
                        AIIMS is not just a single hospital but a conglomerate of facilities. It includes over 1500 beds spread across various centers:
                    </p>
                    <ul>
                        <li>Main Hospital</li>
                        <li>Dr. Rajendra Prasad Centre for Ophthalmic Sciences</li>
                        <li>Cardiothoracic Centre</li>
                        <li>Neurosciences Centre</li>
                        <li>Institute Rotary Centre Hospital</li>
                        <li>De-addiction Centre</li>
                    </ul>
                    <p>
                        This complex caters to about 1.5 million outpatients and 80,000 inpatients every year. AIIMS performed over 100,000 surgical operations last year.
                    </p>

                    <h2>Notable Achievements</h2>
                    <p>
                        <strong>Coronary Bypass Surgery:</strong> In progress at the Cardiothoracic Centre, AIIMS.
                        <br />
                        <strong>Heart Transplant:</strong> Shri Devi Ram was the first heart transplant patient in India, operated on 3 August 1994 at AIIMS.
                    </p>
                    <p>
                        AIIMS's strength lies not just in numbers but in the availability of the best team of medical and surgical experts under one roof. State-of-the-art equipment, from autoanalyzers to linear accelerators, MRI to gamma knives, are available at AIIMS. The institution takes the initiative to integrate new technologies as they become available.
                    </p>

                    <h2>Pioneering Medical Procedures</h2>
                    <p>
                        AIIMS has pioneered several medical procedures, including:
                    </p>
                    <ul>
                        <li>Cardiac catheterization</li>
                        <li>Heart valve surgery</li>
                        <li>Porto-caval surgery</li>
                        <li>Neonatal surgery</li>
                        <li>Joint replacement</li>
                        <li>Cochlear implant</li>
                        <li>Multi-organ transplants (cornea, kidney, bone marrow, heart, liver)</li>
                    </ul>
                    <p>
                        Our results in these sophisticated medical and surgical procedures are among the best globally, and these cutting-edge modalities are indeed being utilized for the underprivileged.
                    </p>

                    <h2>Technological Advancements</h2>
                    <p>
                        <strong>Laser Treatment:</strong> Patients undergo laser treatment at the Dr. Rajendra Prasad Centre for Ophthalmic Sciences.
                        <br />
                        <strong>Nursing Care:</strong> The advanced facilities and nursing care at AIIMS have significantly contributed to the survival of quadruplets and many other patients.
                    </p>

                    <h2>Training and Education</h2>
                    <p>
                        AIIMS's impact extends beyond its own hospital. It has trained physicians and surgeons who have spread across the country and abroad. For decades, AIIMS has been a primary source of training for neurologists, neurosurgeons, cardiac surgeons, pediatric surgeons, and gastroenterologists. The institution has also introduced new DM courses in endocrinology and medical oncology.
                    </p>
                    <p>
                        The nation has invested heavily in AIIMS to provide the best possible healthcare, irrespective of paying capacity. Few institutions have fulfilled their commitments to such a degree.
                    </p>

                    <h2>Advanced Diagnostic Technologies</h2>
                    <p>
                        <strong>Interface Cell:</strong> An interphase cell from a Philadelphia Chromosome positive patient with chronic myeloid leukemia shows a BCR/ABL chimeric fusion gene (oncogene).
                        <br />
                        <strong>Magnetic Resonance Imaging:</strong> A neurosurgical patient undergoing MRI at the state-of-the-art facility.
                        <br />
                        <strong>Multichannel Autoanalyzer:</strong> Provides quick estimation of several blood chemistry parameters using a very small blood sample.
                    </p>

                    <h2>Extension Services</h2>
                    <p>
                        The Comprehensive Rural Health Services Project (CRHSP) was established in Ballabgarh, about 35 km from AIIMS. It serves as a model for rural healthcare delivery, including a subdivisional hospital, primary health centers, subcenters, and health workers. This project demonstrates significant improvements in health with modest resources.
                    </p>
                    <p>
                        Most health indices set as goals for the year 2000 have already been achieved in the Ballabgarh block, including:
                    </p>
                    <ul>
                        <li>Infant mortality rate of 54.6 per 1000 live births (national average: 74)</li>
                        <li>No maternal deaths (national figure: 200-400 per 100,000 live births)</li>
                        <li>Immunization coverage of over 95% for children and pregnant women</li>
                        <li>High coverage for vitamin A, ORT use during diarrhea, and early appropriate treatment of pneumonia in children</li>
                    </ul>

                    <h2>Community-Based Urban Health Projects</h2>
                    <p>
                        AIIMS's extension activities also include a model community-based urban health project and De-addiction Centres.
                    </p>

                    <h2>Exhibition and Outreach</h2>
                    <p>
                        Mrs. Renuka Chowdhury, Minister of Health & Family Welfare and President of AIIMS, inaugurated an exhibition on Lifestyle and Health at AIIMS on 25 September 1997.
                        <br />
                        An outdoor outpatient clinic in a slum area is part of the urban extension service of the Center for Community Medicine, AIIMS.
                    </p>
                </div>
            </section>
        </>
    );
}

export default PatientCare;
