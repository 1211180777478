import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import logo from '../../Assets/logo.png';

const Footer = () => {
  return (
    <>

      <footer className="footer">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-3 footer-logo mb-4">
              <img src={logo} alt="Company Logo" className="img-fluid mb-3" />
              <div className="social-media-links">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
              <div className="links mt-2 ">
                <div className="single-info">
                  Call Now :
                  <a href="tel:+918420580757"> +91-8420580757</a>
                </div>
                <div className="single-info">
                  Email Us :
                  <a href="mailto:support@aiims-jobs.com"> support@aiims-jobs.com</a>
                </div>

              </div>
            </div>

            <div className="col-md-3">
              <h4>About Us</h4>
              <ul>
                <li><Link to="/about-us/introduction">Introduction</Link></li>
                <li><Link to="/about-us/about-aiims">About AIIMS</Link></li>
                <li><Link to="/about-us/teaching">Teaching</Link></li>
                <li><Link to="/about-us/research">Research</Link></li>
                <li><Link to="/about-us/patient-care">Patient Care</Link></li>
                <li><Link to="/about-us/director">Director</Link></li>
                <li><Link to="/about-us/management">Management</Link></li>
                <li><Link to="/about-us/minutes-of-meeting">Minutes of Meeting</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Quick Links</h4>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/departments-centers">Departments & Centers</Link></li>
                <li><Link to="/library">Library</Link></li>
                <li><Link to="/events">Events</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/updates">Updates</Link></li>
              </ul>
            </div>
            <div className="col-md-3">
              <h4>Staff Services</h4>
              <ul>
                <li><Link to="/staff-services/staff-login">Staff Login</Link></li>
                <li><Link to="/staff-services/job-invitation">Job Invitation</Link></li>
              </ul>
            </div>

            <div className="col-md-12 text-center">
              <div className="footer-info">
                <p>Copyright &copy; {new Date().getFullYear()} AIIMS All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
