import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import CenterLogin from "./Pages/StudentServices/CenterLogin/CenterLogin";
import About from "./Pages/About/About";
import AboutAiims from "./Pages/About/AboutAiims";
import Teaching from "./Pages/About/Teaching";
import Research from "./Pages/About/Research";
import PatientCare from "./Pages/About/PatientCare";
import Director from "./Pages/About/Director";
import Management from "./Pages/About/Management";
import Mom from "./Pages/About/Mom";
import JobInvitation from "./Pages/JobInvitation/JobInvitation";
import ApplyJob from "./Pages/ApplyJob/ApplyJob";
import DepartmentCenters from "./Pages/Others/DepartmentCenters";
import Library from "./Pages/Others/Library";
import Events from "./Pages/Others/Events";
import Contact from "./Pages/Contact/Contact";
import Updates from "./Pages/Others/Updates";

function App() {
  return (
    <>
      <Header/>
      <Routes>
        <Route path='/' element={<Home />} />

        {/* About ===  */}
        <Route path='/about-us/introduction' element={<About />} />

        <Route path='/about-us/about-aiims' element={<AboutAiims/>} />
        <Route path='/about-us/teaching' element={<Teaching/>} />
        <Route path='/about-us/research' element={<Research/>} />
        <Route path='/about-us/patient-care' element={<PatientCare/>} />
        <Route path='/about-us/director' element={<Director/>} />
        <Route path='/about-us/management' element={<Management/>} />
        <Route path='/about-us/minutes-of-meeting' element={<Mom/>} />

        <Route path='/apply-job' element={<ApplyJob/>} />

        {/* Others ===  */}
        <Route path='/departments-centers' element={<DepartmentCenters/>} />
        <Route path='/library' element={<Library/>} />
        <Route path='/events' element={<Events/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/updates' element={<Updates/>} />

        {/* student-services */}
        <Route path='/staff-services/staff-login' element={<CenterLogin />} />
        <Route path='/staff-services/job-invitation' element={<JobInvitation />} />
        
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
