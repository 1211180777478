import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Events = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Upcoming Events - Stay Updated with Our Latest Events</title>
                <meta name="keywords" content="Events, Upcoming Events, Schedule, Calendar, Activities" />
                <meta name="description" content="Stay updated with our upcoming events, including conferences, workshops, and seminars. Find details and schedules here." />
            </Helmet>
            <section className='content'>
                <div className="container my-5">
                    <h1 className="mb-4">Upcoming Events</h1>
                    <p>Stay informed about our latest events and activities. We host a variety of events including conferences, workshops, seminars, and more. Below is a list of our upcoming events with details on each.</p>

                    <div className="event">
                        <h2 className="mt-4">Annual Medical Conference 2024</h2>
                        <p><strong>Date:</strong> June 15, 2024</p>
                        <p><strong>Location:</strong> Grand Hall, Main Campus</p>
                        <p>Join us for our Annual Medical Conference, featuring keynote speakers from around the world, interactive sessions, and networking opportunities. Register early to secure your spot.</p>
                    </div>

                    <div className="event mt-5">
                        <h2 className="mt-4">Healthcare Innovation Workshop</h2>
                        <p><strong>Date:</strong> July 22, 2024</p>
                        <p><strong>Location:</strong> Innovation Center, Building B</p>
                        <p>Participate in our Healthcare Innovation Workshop where experts will share the latest advancements in medical technology and research. This workshop is ideal for professionals looking to stay at the forefront of healthcare innovation.</p>
                    </div>

                    <div className="event mt-5">
                        <h2 className="mt-4">Community Health Fair</h2>
                        <p><strong>Date:</strong> August 10, 2024</p>
                        <p><strong>Location:</strong> Community Center, Downtown</p>
                        <p>Our Community Health Fair offers free health screenings, educational seminars, and wellness activities. It's a great opportunity for community members to get involved and learn more about maintaining a healthy lifestyle.</p>
                    </div>

                    <div className="event mt-5">
                        <h2 className="mt-4">Pediatric Care Seminar</h2>
                        <p><strong>Date:</strong> September 5, 2024</p>
                        <p><strong>Location:</strong> Conference Room A, Main Campus</p>
                        <p>This seminar focuses on pediatric care practices and advancements. Healthcare professionals and parents are encouraged to attend to gain valuable insights into child health and development.</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Events;
