import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';


const Teaching = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Teaching at AIIMS - Courses and Student Details</title>
                <meta name="keywords" content="AIIMS, Teaching, Courses, Students, Medical Education, Nursing, Paramedical" />
                <meta name="description" content="Explore the various courses and student details at AIIMS, including undergraduate, postgraduate, and doctoral programs for medical, nursing, and paramedical education." />
            </Helmet>
            
            <section className="content">
                <div className="container my-5">
                    <h1 className="mb-4">AIIMS Academic Section Overview</h1>
                    <p>The Academic Section develops policy and plans and executes academic activities at AIIMS, New Delhi. These activities encompass the undergraduate, postgraduate, and doctoral programmes for medical, nursing, and paramedical courses. These activities are conducted by the undergraduate, postgraduate, and paramedical cells.</p>

                    <h2 className="mt-5 mb-3">Undergraduate Education</h2>
                    <p>The section handles post-admission formalities for new students, develops and revises curricula, and administers teaching programmes including internal assessment of undergraduate students admitted to various courses. The courses include MBBS, B.Sc. (Hons) Medical Technology in Radiography, Ophthalmic Techniques, Operation Theatre Technology, B.Sc. Dental Hygiene, B.Sc. Dental Operating Room Assistant (DORA), B.Sc. Nursing (Post-certificate), and B.Sc. (Hons) Nursing courses.</p>

                    <h3 className="mt-4 mb-3">Bachelor of Medicine and Bachelor of Surgery (MBBS)</h3>
                    <p>The pattern of education in the MBBS course was reviewed and re-structured in July 2004. The MBBS course is of 5½ years duration and comprises 3 phases and an internship. The details are as follows:</p>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Phase</th>
                                <th scope="col">Duration (years)</th>
                                <th scope="col">Training</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>First</td>
                                <td>One</td>
                                <td>Pre-Clinical</td>
                            </tr>
                            <tr>
                                <td>Second</td>
                                <td>One-and-a-half</td>
                                <td>Para-clinical</td>
                            </tr>
                            <tr>
                                <td>Third</td>
                                <td>Two</td>
                                <td>Clinical</td>
                            </tr>
                            <tr>
                                <td>Internship</td>
                                <td>One</td>
                                <td>Compulsory rotation in various departments</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>At present, 125 Indian students are admitted every year to the MBBS course. Seven foreign national students are also admitted each year. Beginning in 2020, these admissions are made based on merit through the National Eligibility Entrance Test (NEET) conducted by the National Testing Agency.</p>
                    <p>As of 31 March 2021, 523 MBBS students including 73 interns were on rolls. Nine meritorious MBBS students were awarded scholarships for securing first, second, and third positions in the first, second, and final phases of professional examinations.</p>

                    <h2 className="mt-5 mb-3">Paramedical and Nursing Education</h2>
                    <p>In 2020–2021, 98 students were admitted to the B.Sc. (Hons) Nursing course. For the B.Sc. Nursing (Post-certificate) course, 45 students were admitted.</p>

                    <h3 className="mt-4 mb-3">Students Admitted to Various B.Sc. Courses</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Course</th>
                                <th scope="col">General</th>
                                <th scope="col">SC</th>
                                <th scope="col">ST</th>
                                <th scope="col">OBC</th>
                                <th scope="col">EWS</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ophthalmic Techniques</td>
                                <td>9</td>
                                <td>2</td>
                                <td>2</td>
                                <td>5</td>
                                <td>2</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>Medical Technology in Radiology</td>
                                <td>5</td>
                                <td>1</td>
                                <td>1</td>
                                <td>3</td>
                                <td>1</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>Operation Theatre Technology</td>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>2</td>
                                <td>1</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>Dental Hygiene</td>
                                <td>1</td>
                                <td>1</td>
                                <td>0</td>
                                <td>2</td>
                                <td>1</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>Dental Operating Room Assistant</td>
                                <td>2</td>
                                <td>2</td>
                                <td>1</td>
                                <td>3</td>
                                <td>2</td>
                                <td>10</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-4 mb-3">Students on Rolls in Various Courses (as on 31 March 2021)</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Course</th>
                                <th scope="col">Number of Students</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>B.Sc Nursing (Post-basic)</td>
                                <td>78</td>
                            </tr>
                            <tr>
                                <td>B.Sc (Hons) Nursing</td>
                                <td>323</td>
                            </tr>
                            <tr>
                                <td>B.Sc (Hons) Ophthalmic Techniques</td>
                                <td>74</td>
                            </tr>
                            <tr>
                                <td>B.Sc (Hons) Medical Technology in Radiology</td>
                                <td>39</td>
                            </tr>
                            <tr>
                                <td>B.Sc Operation Theatre Technology</td>
                                <td>31</td>
                            </tr>
                            <tr>
                                <td>B.Sc Dental Hygiene</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>B.Sc Dental Operating Room Assistant</td>
                                <td>22</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2 className="mt-5 mb-3">Postgraduate Education</h2>
                    <p>The Academic Section oversees all activities related to the admission and training of postgraduate students including junior and senior residents. It handles all activities related to Fellowship, Ph.D., DM, MCh, MD, MS, MDS, MCh/DM (Direct 6 years course), M Biotechnology (MBiotech), and MSc courses in different disciplines.</p>

                    <h3 className="mt-4 mb-3">Admissions in Various Courses (2020-2021)</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Name of Course</th>
                                <th scope="col">July 2020</th>
                                <th scope="col">January 2021</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>MSc / MBiotech / MSc Nursing (August)</td>
                                <td>76</td>
                                <td>*</td>
                                <td>76</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>MD / MS/ MDS/ MCh/ DM (Direct 6 Years)</td>
                                <td>260</td>
                                <td>186</td>
                                <td>446</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>DM / MCh</td>
                                <td>96</td>
                                <td>105</td>
                                <td>201</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>PhD</td>
                                <td>53</td>
                                <td>37</td>
                                <td>90</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Fellowship</td>
                                <td>17</td>
                                <td>4</td>
                                <td>21</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export default Teaching;
