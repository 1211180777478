import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';


const AboutAiims = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
        <Helmet>
                <title>About AIIMS - At a Glance</title>
                <meta name="keywords" content="AIIMS, Medical Education, Nursing, Paramedical, Academic Section" />
                <meta name="description" content="A comprehensive overview of AIIMS, including student statistics and academic achievements for the year 2013-2014." />
            </Helmet>
            <section className="content">
                <div className="container my-5">
                    <h1 className="mb-4">AIIMS AT A GLANCE</h1>
                    <h2 className="mb-4">Information Pertaining to Academic Section for the Year 2013-2014</h2>

                    <h3 className="mb-3">No. of Students On-Roll in Various Courses at AIIMS as on 31/3/2014:</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Course</th>
                                <th scope="col">No. of Students</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>MBBS Students</td>
                                <td>383</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>B.Sc. (Hons.) Nursing, B.Sc. Nursing (Post Certificate), B.Sc. (Hons.) MTR & B.Sc. (Hons) Ophthalmic Tech. Students</td>
                                <td>393</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>MD, MS, MDS, MHA & M.Ch. (6 years) Students</td>
                                <td>655</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>DM & M.Ch Students</td>
                                <td>224</td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>Ph.D. Students</td>
                                <td>419</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>M.Sc., M.Sc. Nursing & M.Biotechnology Students</td>
                                <td>109</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-5 mb-3">No. of Students Who Have Been Awarded Degrees Till the 41st Convocation Held in 2013:</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Degree</th>
                                <th scope="col">No. of Students</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>MBBS</td>
                                <td>2646</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>B.Sc. (Hons.) Nursing</td>
                                <td>2674</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>MD, MS, MDS, MHA & M.Ch. (6 years)</td>
                                <td>5198</td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>DM & M.Ch</td>
                                <td>1344</td>
                            </tr>
                            <tr>
                                <th scope="row">5</th>
                                <td>Ph.D.</td>
                                <td>996</td>
                            </tr>
                            <tr>
                                <th scope="row">6</th>
                                <td>M.Sc., M.Sc. Nursing & M.Biotechnology</td>
                                <td>764</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default AboutAiims;
