import React from 'react';
import { Helmet } from 'react-helmet';

const Research = () => {
  return (
    <>
      <Helmet>
        <title>Research at AIIMS - Innovative Solutions for Health Challenges</title>
        <meta name="description" content="Explore the pioneering research initiatives at AIIMS, focusing on solving national health challenges through advanced scientific methods and technologies." />
        <meta name="keywords" content="AIIMS research, medical research, health challenges, scientific innovation, advanced medical technologies" />
      </Helmet>
      <section className="content">
      <div className="container my-5">
        <h1>The Power of Science in Solving National Challenges</h1>
        <blockquote>
          <p>
            <strong>Jawaharlal Nehru</strong> once famously stated:
            <br />
            <em>
              “It is science alone that can solve the problems of hunger and poverty, insanitation and illiteracy, of superstition and deadening custom and tradition, of vast resources running to waste, of a rich country inhabited by starving people.”
            </em>
          </p>
        </blockquote>

        <h2>Research at AIIMS: A Mission Beyond Curiosity</h2>
        <p>
          At AIIMS, research is more than an academic pursuit—it's a mission dedicated to advancing knowledge and improving society through three fundamental objectives:
        </p>
        <ul>
          <li>
            <strong>Creation of New Knowledge</strong>: The primary purpose of research at AIIMS is to satisfy intellectual curiosity through disciplined scientific activity, leading to the generation of new knowledge that can push the boundaries of medical science.
          </li>
          <li>
            <strong>Enhancement of Teaching</strong>: Research plays a crucial role in improving the quality of education at AIIMS. Teachers engaged in research stay updated with the latest advancements and pass on a spirit of inquiry and critical thinking to their students, fostering a culture of innovation.
          </li>
          <li>
            <strong>Cultivation of Scientific Temper</strong>: AIIMS is committed to cultivating a scientific temper within the community. By challenging superstitions and the sway of unexamined authority, the institution aims to nurture a society that values evidence-based thinking and rationality.
          </li>
        </ul>

        <h2>Image Analysis System</h2>
        <p>
          AIIMS employs advanced image analysis systems for quantitative and stereological studies of tissues, contributing to a deeper understanding of various medical conditions. This technology is just one example of how AIIMS integrates cutting-edge tools into its research endeavors.
        </p>

        <h2>Rotavirus Vaccine Development</h2>
        <p>
          AIIMS has demonstrated that rotavirus is a major cause of diarrheal disease. In collaboration with the Department of Biotechnology, AIIMS has developed a rotavirus vaccine that is now ready for phase-1 trials to be started this year.
        </p>

        <h2>Early and Sure Diagnosis of Tuberculosis</h2>
        <p>
          AIIMS has developed a PCR test for the diagnosis of tuberculosis, particularly useful for extrapulmonary cases. This test provides results within 24-48 hours, a significant improvement over conventional tests that take 6-8 weeks.
        </p>

        <h2>Leprosy Research</h2>
        <p>
          Contributions of AIIMS towards research on leprosy include:
        </p>
        <ul>
          <li>Development of an anti-leprosy vaccine, which is undergoing trials in Kanpur district.</li>
          <li>Trials of interferon in the treatment of leprosy.</li>
          <li>Use of DNA technology to identify genes of diagnostic significance.</li>
          <li>Development of tests to identify patients at risk of developing life-threatening reactions.</li>
          <li>A behavioral physiology study in progress.</li>
        </ul>

        <h2>Malaria Research</h2>
        <p>
          Using modern DNA technologies, AIIMS has made it possible to study the strains of the parasite responsible for malaria epidemics in India for the first time.
        </p>

        <h2>HIV Diagnostic Test</h2>
        <p>
          AIIMS has developed a highly sensitive and specific ELISA system for detecting HIV antibodies. This system is cost-effective, being less than half the price of imported kits, and has identified many early cases that imported kits missed. AIIMS has been a Reference Centre for HIV/AIDS since 1986 and the National HIV Reference Centre since 1992.
        </p>

        <h2>Research to Control Liver Disease</h2>
        <p>
          AIIMS's research on liver disease includes epidemiological, clinical, and basic studies that have led to the development of an indigenous diagnostic test and a candidate vaccine, as well as a diagnostic assay system for screening blood donors for hepatitis C virus.
        </p>

        <h2>Diagnostic Test for Plague</h2>
        <p>
          AIIMS was the first place in the world to use PCR on formalin-fixed autopsy material to detect and confirm plague during the 1994 outbreak. The institution has cloned, sequenced, and expressed the f1 and pla genes of Yersinia pestis, which are immunoreactive and are being used for developing a serological test for plague.
        </p>

        <h2>Iodine Deficiency Disorders</h2>
        <p>
          AIIMS has defined the impact of iodine deficiency on growth and development, especially the role of thyroxine in brain development in human fetuses. Research at AIIMS has been instrumental in the 'Universal Salt Iodization Programme' of the Government of India, which started in 1986. The contributions extend to several countries, including Bhutan, Nepal, Bangladesh, Maldives, Indonesia, Thailand, and various African and Middle Eastern nations.
        </p>

        <h2>Anti-Fertility Vaccine</h2>
        <p>
          AIIMS has been a pioneer in the development of an anti-human chronic gonadotropin (hCG) vaccine over the past 25 years. Much of the research began at AIIMS, including morphometric analysis of immuno-cytochemically stained endometrium and photomicrography in progress.
        </p>

        <h2>Post-Coital and Post-Ovulatory Contraception</h2>
        <p>
          Research on endocrine and paracrine mechanisms in embryo implantation and the development of post-coital and post-ovulatory contraceptives is conducted in the Primate Implantation Biology Laboratory. This lab is recognized as a non-US Centre for Contraceptive Research and Development by the Andrew Mellon Foundation and is one of 13 such Centres worldwide supported by the Rockefeller Foundation.
        </p>

        <h2>Exploration of Antarctica</h2>
        <p>
          Since 1990, AIIMS has participated in India's Antarctic exploration efforts. The institution provides healthcare to expedition members and conducts research on chronobiology, physiological responses to the Antarctic environment, and performance under extreme conditions.
        </p>

        <h2>Dr. B.B. Dikshit Library</h2>
        <p>
          Named after the first Director of AIIMS, the Dr. B.B. Dikshit Library is a valuable resource with 61,423 books, 53,547 journals, and 14,008 reports in Biomedical Sciences. It receives 490 journals and 80 newsletters annually and is open seven days a week from 8 a.m. to 2 a.m. The library features computer facilities, a microfilm library reader, and a reader-printer facility, playing a crucial role in supporting research activities at AIIMS.
        </p>
      </div>
      </section>
    </>
  );
}

export default Research;
